export const SEND_TWILIO_MESSAGE_REQUEST = 'SEND_TWILIO_MESSAGE_REQUEST';
export const SEND_TWILIO_MESSAGE_SUCCESS = 'SEND_TWILIO_MESSAGE_SUCCESS';
export const SEND_TWILIO_MESSAGE_FAIL = 'SEND_TWILIO_MESSAGE_FAIL';

export const GET_VIMEO_SHOWCASE_REQUEST = 'GET_VIMEO_SHOWCASE_REQUEST';
export const GET_VIMEO_SHOWCASE_SUCCESS = 'GET_VIMEO_SHOWCASE_SUCCESS';
export const GET_VIMEO_SHOWCASE_FAIL = 'GET_VIMEO_SHOWCASE_FAIL';

export const SEND_SENDGRID_EMAIL_REQUEST = 'SEND_SENDGRID_EMAIL_REQUEST';
export const SEND_SENDGRID_EMAIL_SUCCESS = 'SEND_SENDGRID_EMAIL_SUCCESS';
export const SEND_SENDGRID_EMAIL_FAIL = 'SEND_SENDGRID_EMAIL_FAIL';
